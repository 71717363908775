<template>
    <div>
        <vx-card>

            <div slot="no-body" class="tabs-container px-6 pt-6">

                <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
                    <vs-tab :label="$t('menuOrganization.organization')" icon-pack="feather" icon="icon-globe">
                        <div class="tab-text">
                            <organization-tab-information class="mt-4" :data="organizationInfo"/>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </div>

        </vx-card>
    </div>

</template>

<script>
import OrganizationTabInformation from "./OrganizationTabInformation"

export default {
    components: {
        OrganizationTabInformation,
    },
    data() {
        return {
            organizationInfo: null,
            activeTab: 0,
        }
    },
    methods: {
        organizationList() {
            this.$router.push('/admin/manage-organization').catch(() => {
            })
        }
    },
    created() {
        let organizationInfo = Object.assign({}, this.$store.state.organizationUpdateInfo || null);

        if(Object.keys(organizationInfo).length === 0 && organizationInfo.constructor === Object) {
            return this.organizationInfo = {
                acronym: null,
                active: 0,
                expiredDate: null,
                adminAccount: null,
                name: null,
                phoneNumber: null,
                type: null,
                address: {
                    street: null,
                    city: null,
                    district: null,
                    fullAddress: null,
                },
                timeKeepingType: "normal",
                username: null
            }
        }
        if(!organizationInfo.address)
            organizationInfo.address = {};

        this.organizationInfo = organizationInfo;
    }
}
</script>

<style scoped>

</style>
