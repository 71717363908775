<template>
    <div id="organization-tab-info">

        <!-- Content Row -->
        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-2">
                <vs-input class="w-full" :label="$t('root.organizationName')" v-model="organizationInfo.name"
                          data-vv-validate-on="blur"
                          v-validate="'required'" name="organizationName"/>
                <span class="text-danger text-sm">{{ errors.first('organizationName') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
                <vs-input class="w-full" :label="$t('root.organizationAcronym')" v-model="organizationInfo.acronym"
                          data-vv-validate-on="blur"
                          v-validate="'required'" name="organizationAcronym"/>
                <span class="text-danger text-sm">{{ errors.first('organizationAcronym') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
                <vs-input
                    v-validate="'required|email'" data-vv-validate-on="blur" type="email" name="email"
                    label="Email" v-model="organizationInfo.email" autocomplete="off" :disabled="isUpdate" class="w-full"/>
                <span class="text-danger text-sm">{{ errors.first("email")}}</span>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-2">
                <vs-input class="w-full " :label="$t('root.phoneNumber')" v-model="organizationInfo.phoneNumber"
                          data-vv-validate-on="blur" v-validate="'numeric|min:10|max:11'" name="phoneNumber"/>
                <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
            </div>

            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.organizationType') }}</label>
                <v-select class="" v-model="organizationType" :clearable="false" :options="organizationTypeOptions"
                          data-vv-validate-on="blur" v-validate="'required'" name="organizationType">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('organizationType') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.expiredDate') }}</label>
                <datepicker :language="languages['vi']" :format="'dd/MM/yyyy'" v-model="organizationInfo.expiredDate"
                            data-vv-validate-on="input" v-validate="'required'" name="expiredDate"></datepicker>
                <span class="text-danger text-sm">{{ errors.first('expiredDate') }}</span>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.city') }}</label>
                <v-select class="" v-model="province" :clearable="false" :options="cityOptions"
                          data-vv-value-path="mutableValue"
                          data-vv-name="custom"
                          data-vv-validate-on="blur" v-validate="'required'" name="city">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('city') }}</span>
            </div>

            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.district') }}</label>
                <v-select class="" v-model="district" :clearable="false" :options="districtOptions"
                          data-vv-validate-on="blur" v-validate="'required'" name="district">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('district') }}</span>
            </div>

            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.commune') }}</label>
                <v-select class="" v-model="subDistrict" :clearable="false" :options="subDistrictOptions"
                          data-vv-validate-on="blur" v-validate="'required'" name="subDistrict">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('subDistrict') }}</span>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-2">
                <vs-input class="w-full" :label="$t('root.houseNumber')" v-model="organizationInfo.address.houseNumber"
                          v-validate="'required'" name="houseNumber"/>
                <span class="text-danger text-sm">{{ errors.first('houseNumber') }}</span>
            </div>
            <div class="vx-col md:w-2/3 w-full mt-2">
                <vs-input class="w-full" :label="$t('root.address')" v-model="fullAddressModel"
                          v-validate="'required'" name="fullAddress" readonly="true"/>
                <span class="text-danger text-sm">{{ errors.first('fullAddress') }}</span>
            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.partner') }}</label>
                <v-select class="" v-model="timeKeepingType" :clearable="false" :options="timeKeepingTypeOptions"
                          data-vv-validate-on="blur" v-validate="'required'" name="timeKeepingType">
                          <span slot="no-options">{{$t('menuOrganization.emptyList')}}</span>
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('timeKeepingType') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-2">
                <label class="vs-input--label">{{ $t('root.amountTrial') }}</label>
                <vs-input class="w-full" v-model="amountTrial"
                          v-validate="'required|numeric'" name="amountTrial"/>
                <span class="text-danger text-sm">{{ errors.first('amountTrial') }}</span>
            </div>
            <div class="vx-col md:w-1/3 w-full mt-4">
                <vs-checkbox style="padding-top: 20px" v-model="active" false-value="0" true-value="1">
                    {{ $t('root.activeStatus') }}
                </vs-checkbox>
            </div>
        </div>

        <!-- Save & Reset Button -->
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-2 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                            `${isUpdate ? this.$t('button.update') :
                                this.$t('button.create')}`
                        }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="organizationList">
                        {{ $t('button.back') }}
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import vSelect from 'vue-select';
import Datepicker from 'vuejs-datepicker';
import * as lang from 'vuejs-datepicker/src/locale';
import province from '@/assets/utils/province.js';

export default {
    components: {
        vSelect,
        Datepicker
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isUpdate: false,
            organizationInfo: this.data,
            organizationTypeOptions: [
                {label: this.$t('root.null'), value: null},
                {label: this.$t('root.education'), value: "education"},
                {label: this.$t('root.company'), value: "company"},
                {label: this.$t('root.shop'), value: "shop"},
            ],
            languages: lang,
            cityOptions: province.getProvince(),
            districtOptions: [],
            subDistrictOptions: [],
            amountTrial: 0,
            amountTrialId: null,
            timeKeepingTypeOptions: [
                {label: "EVG", value: "normal"},
                {label: "HANET", value: "hanet"}
            ]
        }
    },
    created() {
        // this.cityOptions.unshift({value: null, label: this.$t('root.all')});
        this.districtOptions = province.getDistrict(this.organizationInfo.address.city),
        this.subDistrictOptions = province.getSubDistrict(this.organizationInfo.address.city, this.organizationInfo.address.district);
        //     this.districtOptions.unshift({value: null, label: this.$t('root.all')});
        // this.subDistrictOptions.unshift({value: null, label: this.$t('root.all')});

        let id = this.$route.query.id
        if (id) {
            this.isUpdate = true;
            this.findOrganizationById()
            this.findTrialById()
        }
    },
    computed: {
        province: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.cityOptions.find(x => x.value === this.organizationInfo.address.city)),
                    value: this.organizationInfo.address.city
                }
            },
            set(obj) {
                if (obj.value) {
                    this.organizationInfo.address.city = obj.value;
                    this.districtOptions = province.getDistrict(obj.value);
                    this.organizationInfo.address.district = this.districtOptions[0].value;
                    this.subDistrictOptions = province.getSubDistrict(obj.value, this.organizationInfo.address.district);
                    this.organizationInfo.address.street = this.subDistrictOptions[0].value;
                } else {
                    this.organizationInfo.city = null;
                    this.districtOptions = [{value: null, label: this.$t('root.all')}];
                    this.organizationInfo.district = null;
                    this.subDistrictOptions = [{value: null, label: this.$t('root.all')}];
                    this.organizationInfo.street = null;
                }
            }
        },
        district: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.districtOptions.find(x => x.value === this.organizationInfo.address.district)),
                    value: this.organizationInfo.address.district
                }
            },
            set(obj) {
                this.organizationInfo.address.district = obj.value;
                this.subDistrictOptions = province.getSubDistrict(this.organizationInfo.address.city, obj.value);
                this.organizationInfo.address.street = this.subDistrictOptions[0].value;
            }
        },
        subDistrict: {
            cache: false,
            get() {
                return {
                    label: this.getLabelString(this.subDistrictOptions.find(x => x.value === this.organizationInfo.address.street)),
                    value: this.organizationInfo.address.street
                }
            },
            set(obj) {
                this.organizationInfo.address.street = obj.value
            }
        },
        organizationType: {
            get() {
                return {
                    label: this.getLabelString(this.organizationTypeOptions.find(x => x.value === this.organizationInfo.type)),
                    value: this.organizationInfo.type
                }
            },
            set(obj) {
                this.organizationInfo.type = obj.value
            }
        },
        active: {
            get() {
                return this.organizationInfo.active;
            },
            set(value) {
                this.organizationInfo.active = value ? 1 : 0;
            }
        },
        fullAddressModel: {
            get() {
                if (this.province.value) {
                    let fullAddress = `${this.organizationInfo.address.houseNumber ? this.organizationInfo.address.houseNumber + ', ' : ''}${this.subDistrict.label}, ${this.district.label}, ${this.province.label}`;
                    this.organizationInfo.address.fullAddress = fullAddress;
                    return fullAddress;
                } else {
                    return null;
                }
            },
        },
        timeKeepingType: {
            get() {
                return {
                    label: this.getLabelString(this.timeKeepingTypeOptions.find(x => x.value === this.organizationInfo.timeKeepingType)),
                    value: this.organizationInfo.timeKeepingType
                }
            },
            set(obj) {
                this.organizationInfo.timeKeepingType = obj.value
            }
        },
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        getLabelString(object) {
            return (object && object.label) || this.$t('root.null');
        },
        findOrganizationById() {
            this.$vs.loading()
            let id = this.$route.query.id
            this.$oauth.post(`/organization/find-by-id/${id}`).then(res => {
                this.$vs.loading.close()
                this.organizationInfo = res.data
                this.districtOptions = province.getDistrict(this.organizationInfo.address.city),
                this.subDistrictOptions = province.getSubDistrict(this.organizationInfo.address.city, this.organizationInfo.address.district);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            })
        },
        findTrialById() {
            this.$vs.loading()
            let id = this.$route.query.id
            this.$crm.get(`/trial-history/find-by-organization/${id}`).then(res => {
                this.amountTrial = res.data.amountTrial
            }).catch((err) => {
                this.amountTrial = 0
                this.$vs.loading.close();
                // return this.$vs.notify({
                //     text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                //     iconPack: 'feather',
                //     icon: 'icon-alert-circle',
                //     color: 'danger'
                // })
            })
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;

                const result = await this.$validator.validateAll();
                if (!result) return;

                let url = this.isUpdate ? `/organization/admin/update/${this.organizationInfo.id}` : '/organization/admin/create';
                
                if (this.isUpdate && !this.organizationInfo.adminAccount)
                    this.organizationInfo.adminAccount = 'tempAccount';
                this.organizationInfo.activeStatus = 1;
                this.$vs.loading();
                this.$oauth.post(url, this.organizationInfo).then(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('root.update') : this.$t('root.create')} ${this.$t('root.successOrganization')}`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    })
                    let searchOrganizationDTO = {
                        name: this.organizationInfo.name
                    }
                    this.$oauth.post('/organization/admin/find-by-name', searchOrganizationDTO).then(res => {
                        let organizationId = res.data.id
                        let path = this.isUpdate ? `/trial-history/update-trial/${organizationId}/${this.amountTrial}` : `/trial-history/create-trial/${organizationId}/${this.amountTrial}`
                        this.$crm.post(path).then(() => {
                        }).catch((err) => {
                            this.$vs.loading.close();
                            return this.$vs.notify({
                                text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        })
                    })
                    this.$router.push('/admin/manage-organization').catch(() => {})
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        organizationList() {
            this.$router.push('/admin/manage-organization').catch(() => {
            })
        }
    },
}
</script>
